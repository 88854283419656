/* ------------------------------------------------------------ */
/** NavBar Styling                                             **/
/* ------------------------------------------------------------ */

.Navbar-Container {
    background-color: #141C25;
    color: white;
    display: flex;
    flex-direction: row;
    height: 70px;
    width: 100vw;
}

/* ------------------------------------------- */
/** Logo Header                               **/
/* ------------------------------------------- */

.Navbar-Logo {
    display: flex;
    align-items: center;
    padding: 10px;
}

.Navbar-Logo img {
    margin: 0px 20px;
    cursor: pointer;
    width: 50px;
}

.Navbar-Logo img:hover {
    margin-right: 20px;
    cursor: pointer;
    animation-name: spin;
    animation-duration: 1.2s;
    animation-delay: .5s;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

/* Event App Link */
.Navbar-Link {
    color: white;
    text-decoration: none;
    font-weight: normal;
}

.Navbar-Link:hover {
    color: white;
    transform: scale(1.02);
}

/* ------------------------------------------- */
/*  Nav Items                                  */
/*  - Container for all sidebar items          */
/* ------------------------------------------- */

.Navbar-Items-Container {
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--subheading);
}

/* Each item in the sidebar */
.Navbar-Items-Container-Link {
    font-size: var(--subheading);
    width: calc(100% - 15px);
    padding: 10px;
    text-decoration: none;
    color: white;
    margin-left: 15px;

}

.Navbar-Items-Container-Link:hover {
    cursor: pointer;
    color: white;
    transform: scale(1.01);
    border-radius: 5px 0px 0px 5px;
}

.Navbar-Items-Container-Link-Active {
    border-bottom: 5px solid rgba(64, 119, 218, 1);
    transition-delay: .1s;
    cursor: default;
}
.Navbar-Items-Container-Link-Active:hover {
    transform: scale(1);
    border-radius: 5px 0px 0px 5px;
}

.Navbar-Item-Icon{
    padding-right: 10px;
}